import React, { useState } from 'react';
import { Typography, Button, Card, Grid, CardContent, Chip, Box, List, ListItem, FormControlLabel, Switch } from '@mui/material';
// import { gridSpacing } from 'store/constant';

const cardsData = [
  {
    id: 1,
    type: 'pro',
    title: 'Pro User',
    description: 'Enjoy the benefits of automating your repetitive tasks with AI',
    price: '$99.99 / month',
    recurrency: 'Monthly or $29.99 /yearly',
    mostPopular: false,
    data: ['Access to Automatic Recording Plugins & Tools','1GB Storage', '2 Multiverse automations', 'Ability to withdraw Bonuses', '24/7 Support', '2GB Memory (RAM)','Access to Voice AI automations']
  },
  {
    id: 2,
    type: 'host',
    title: 'Host User',
    description: 'Earn money by leasing your computer for company related tasks or earn from a list of carefully curated deals',
    price: '$149.99 / year',
    recurrency: 'Yearly',
    mostPopular: true,
    data: ['Can earn upto $5,000/Month','Earn $0.5 daily','Access to Earning Software, Training & Resources', 'Ability to withdraw Bonuses', '24/7 Support', 'Access to a myriad of deals']
  },
  {
    id: 3,
    type: 'advanced',
    title: 'Advanced User',
    description: 'Enjoy the benefits of both Pro & Host plans',
    price: '$299.99 / month',
    recurrency: 'Monthly or $89.99 /yearly',
    mostPopular: false,
    data: ['Both Pro & Advanced Features','5GB Storage', 'Ability to withdraw Bonuses','2GB Memory (RAM)','Access to our Latest Features','API Access','Customizations','Reseller Access Portal','Dedicated Representative & Priority Status','Best Deals']
  },
  {
    id: 4,
    type: 'enterprise',
    title: 'Enterprise Users',
    description: 'For organizations with custom business requirements.',
    price: ' contact sales@automateweb.online',
    recurrency: 'Monthly or Yearly',
    mostPopular: false,
    data: ['Dedicated servers','Unlimited Automations', 'Enterprise support & SLAs','Custom security requirements','Custom app integrations & customizations','Custom invoicing','Custom computing requirements','Quantum computer Integrations']
  }
];

function CardDescription({ title, description }) {
  return (
    <CardContent>
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    </CardContent>
  );
}

function CardBilling({ price, recurrency }) {
  return (
    <CardContent>
      <Typography variant="h4" color="primary">
        <strong>{price}</strong>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Billed {recurrency}
      </Typography>
    </CardContent>
  );
}

function CardFeatures({ data }) {
  return (
    <CardContent>
      <List>
        {data.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
    </CardContent>
  );
}

function CardAction({ clickMe }) {
  return (
    <CardContent>
      <Button variant="contained" color="primary" onClick={clickMe}>
        UPGRADE
      </Button>
    </CardContent>
  );
}

function PricingCard(props) {
  const [monthly, setMonthly] = useState(false);
  const { type, title, description, price, recurrency, mostPopular, data, clickMe } = props;

  return (
    <Card elevation={3} sx={{ minWidth: 275, margin: 2 }}>
      {mostPopular && (
        <Chip label="Most Popular" color="secondary" sx={{ position: 'absolute', top: 16, left: 16 }} />
      )}
      <CardDescription title={title} description={description} />
      <CardBilling price={price} recurrency={recurrency} />
      <CardFeatures data={data} />
      <CardAction clickMe={clickMe} />
    </Card>
  );
}

{/* <div className="flex items-center mt-5">
      <p>Monthly Billing</p>
      <FormControlLabel
        control={
          <Switch
            className="-mr-1 ml-3"
            name="monthly"
            checked={monthly}
            onChange={() => setMonthly(!monthly)}
          />
        }
        label="Yearly Billing"
        className="mr-2" // Adjusted to manage spacing, replace 'mr' with specific margin if needed
      />
      <span className="-m-3 px-2 text-yellow-500 bg-red-100 rounded-full">
        -25%
      </span>
    </div> */}

function App() {
  function handleClick() {
    console.log('Button clicked!');
  }

  return (
    <Grid container  justifyContent="left">
      {cardsData.map((props) => (
        <Grid item lg={4} md={6} sm={12} xs={12} key={props.id}>
          <PricingCard {...props} clickMe={handleClick} />
        </Grid>
      ))}
    </Grid>
  );
}

export default App;
