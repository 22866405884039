// material-ui
// import { useTheme } from '@mui/material/styles';

// import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.png';

const Logo = () => {
  // const theme = useTheme();
  return <img src={logo} alt="Automate Web" width="100" />;
};
export default Logo;
