// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const InstructionsPage = () => (
  <MainCard title="Elevate Your Workflow with Our AI-Powered Automation">
    <Typography variant="body2">
        <p>Welcome to the future of productivity, where your repetitive tasks are not just simplified but transformed. Our platform leverages cutting-edge AI to automate your workflows, freeing you to focus on what truly matters. By registering an account, you not only step into a realm of infinite possibilities but also become eligible for an exclusive <strong>$50 bonus</strong>. This is just the beginning of our journey together.</p>
        <h4>Discover the Power of One</h4>
        <p>Our <strong>Forever Free</strong> version introduces you to the world of automation with one automation slot. Imagine recording an action you perform repetitively on a browser, using nothing more than your mobile phone or camera. Upload this recording, and like magic, our AI crafts an automation through our browser plugin. Dive deeper, fill in some more details, try out our sample, and the $50 bonus is yours. Use it to explore, withdraw, or even better, upgrade your account.</p>
        <h4>Flexible Financials</h4>
        <p>With a variety of withdrawal and deposit methods, including crypto, Visa/Mastercard, and bank transfers (dependent on country and availability), financial flexibility is at your fingertips.</p>
        <h3>Upgrade to Pro: Where Limitless Becomes Standard</h3>
        <p>Our subscription plans are designed to cater to diverse needs and ambitions:</p>
        <h4>Pro User: The Power User's Dream</h4>
        <ul>
        <li><strong>Price:</strong> $99.99 (Enjoy 70% off on annual payments)</li>
        <li><strong>Features:</strong>
        <ul>
        <li>Access to Automatic Recording Plugins &amp; Tools</li>
        <li>1GB Storage</li>
        <li>2 Multiverse Automations</li>
        <li>Bonus Withdrawals</li>
        <li>24/7 Support</li>
        <li>2GB RAM</li>
        <li>Voice AI Automations</li>
        </ul>
        </li>
        </ul>
        <h4>Host User: Earn as You Grow</h4>
        <ul>
        <li><strong>Price:</strong> $149.99/year (Exclusive annual plan)</li>
        <li><strong>Features:</strong>
        <ul>
        <li>Earn up to $5,000/Month</li>
        <li>Daily Earnings of $0.5</li>
        <li>Earning Software, Training &amp; Resources</li>
        <li>Bonus Withdrawals</li>
        <li>24/7 Support</li>
        <li>Access to Exclusive Deals</li>
        </ul>
        </li>
        </ul>
        <h4>Advanced User: The Ultimate Package</h4>
        <ul>
        <li><strong>Price:</strong> $299.99/month (70% off for annual subscriptions)</li>
        <li><strong>Features:</strong>
        <ul>
        <li>Both Pro &amp; Host Features</li>
        <li>5GB Storage</li>
        <li>Bonus Withdrawals</li>
        <li>2GB RAM</li>
        <li>Latest Features &amp; API Access</li>
        <li>Customizations &amp; Reseller Portal</li>
        <li>Dedicated Representative &amp; Priority Status</li>
        <li>Access to Best Deals</li>
        </ul>
        </li>
        </ul>
        <h4>Enterprise User: Customized for Excellence</h4>
        <ul>
        <li><strong>Pricing:</strong> Custom</li>
        <li><strong>Features:</strong>
        <ul>
        <li>Dedicated Servers &amp; Unlimited Automations</li>
        <li>Enterprise-Level Support &amp; SLAs</li>
        <li>Custom Security, App Integrations, &amp; Customizations</li>
        <li>Custom Invoicing &amp; Computing Needs</li>
        <li>Quantum Computer Integrations</li>
        </ul>
        </li>
        </ul>
        <h3>Join Us and Transform Your Productivity</h3>
        <p>Our mission is to empower you with tools that not only enhance your efficiency but revolutionize the way you work. Upgrade today and unlock the full potential of our platform. Your journey towards unparalleled productivity and financial freedom starts here. Upgrade now and redefine what's possible.</p>
    </Typography>
  </MainCard>
);

export default InstructionsPage;
