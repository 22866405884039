import React, { useContext, useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, query, where  } from 'firebase/firestore';

import InstructionsPage from 'views/instructions-page';
import DealsPage from 'views/deals-page';
import AutomationsPage from 'views/automations-page';
import TransactionsPage from 'views/transactions-page';
import WithdrawPage from 'views/withdraw-page';
import UpgradePage from 'views/upgrade-page';
import ClaimsPage from 'views/claims-page';

// import { AuthContext } from '../contexts/AuthContext';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const firebaseConfig = {
  apiKey: "AIzaSyCAbrjyNwUqTMtrnvl1zdH6FdXRtb5f_IE",
  authDomain: "automate-web.firebaseapp.com",
  projectId: "automate-web",
  storageBucket: "automate-web.appspot.com",
  messagingSenderId: "189738855795",
  appId: "1:189738855795:web:23f005abf436f0422d2906",
  measurementId: "G-NP4G5MDSKX"
};

// sample page routing
// const InstructionsPage = Loadable(lazy(() => import('views/instructions-page')));
// const { user } = useContext(AuthContext);
// Define a custom protected route component
const ProtectedRoute = ({ allowedRoles }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [personalDetails, setPersonalDetails] = useState(null);
  const [walletDetails, setWalletDetails] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [db, setDb] = useState(null);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    setDb(firestore);
  }, []);

  useEffect(() => {
    if (db && user) {
      handleAddData();
      addWalletData();
      addTransactions();
    }
  }, [db, user]);

  const handleAddData = async () => {
    try {
      const personalDetails = {
        uid: user.uid,
        email: user.email,
        do_you_have_money: false,
        account_type: 'free',
        active_withdrawal: false,
        cc: '',
        contacts: [],
        country: '',
        date_of_birth: '',
        first_name: '',
        last_name: '',
        how_much: 0.00,
        identity: '',
        mail: [],
        messages: [],
        mobile: '',
        nin: '',
        number_of_referrals: 0,
        profile_picture: '',
        referral_code: '',
        registered_mm_first_name: '',
        registered_mm_last_name: '',
        ssn: '',
        whatsapp: [],
        successfully_rbed: false,
        type_of_user: '',
      };

      // Check if email already exists
      const collectionRef = collection(db, 'personal_details');
      const emailQuery = query(collectionRef, where('email', '==', user.email));
      const emailQuerySnapshot = await getDocs(emailQuery);
      const querySnapshot = await getDocs(query(collectionRef, where('email', '==', user.email)));
      

      if (!emailQuerySnapshot.empty) {
        // console.error('Email already exists');
        const personalDetailsArray = querySnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        setPersonalDetails(personalDetailsArray);
        return;
      }
  
      // Email is unique, add to Firestore
      await addDoc(collectionRef, personalDetails);
      console.log('Data added successfully');
    } catch (error) {
      console.error('Error adding data: ', error);
    }
  };

  const addWalletData = async () => {
    try {
      const walletDetails = {
        uid: user.uid,
        email: user.email,
        balance: 0.00,
        datetime: new Date,
        number_of_transactions: 0
      };

      // Check if email already exists
      const collectionRef = collection(db, 'wallet');
      const emailQuery = query(collectionRef, where('email', '==', user.email));
      const emailQuerySnapshot = await getDocs(emailQuery);
      const querySnapshot = await getDocs(query(collectionRef, where('email', '==', user.email)));
      

      if (!emailQuerySnapshot.empty) {
        // console.error('Wallet exists');
        const walletDetailsArray = querySnapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });
        setWalletDetails(walletDetailsArray);
        // localStorage.setItem('personal_details', JSON.stringify(personalDetailsArray));
        return;
      }
  
      // Email is unique, add to Firestore
      await addDoc(collectionRef, walletDetails);
      console.log('Data added successfully');
    } catch (error) {
      console.error('Error adding data: ', error);
    }
  };

  const addTransactions = async () => {
    try {
      const myTransactions = {
        uid: user.uid, // User ID
        email: user.email, // User's email
        amount: 0.00, // Transaction amount
        description: '', // Description of the transaction
        datetime: new Date().toISOString(), // ISO string format of the transaction date and time
        total_number_of_transactions: 0, // Total number of transactions (consider if this is the best place for this information)
        transactionType: 'credit', // 'credit' for incoming, 'debit' for outgoing funds
        currency: 'USD', // Currency code (e.g., USD, EUR)
        status: 'successful', // Transaction status e.g., 'pending', 'completed', 'failed'
        paymentMethod: 'initial credit', // Method of payment
        category: '', // Transaction category e.g., 'groceries', 'utilities'
        recipientSenderInfo: {
          name: '', // Name of the recipient/sender
          account: '', // Account details if necessary
        },
        transactionId: '', // Unique transaction ID or reference number
        createdAt: new Date().toISOString(), // Creation timestamp
        modifiedAt: new Date().toISOString(), // Modification timestamp (if applicable, could be the same as `createdAt` initially)
        geolocation: {
          lat: '', // Latitude
          long: '', // Longitude
        },
        feeInformation: {
          feeAmount: 0.00, // Any transaction fees
          netAmount: 0.00, // Amount after fees
        },
        notesMetadata: '', // Additional notes or metadata about the transaction
        attachments: [], // References to attached files (e.g., receipts, invoices)
      };

      // Check if email already exists
      const collectionRef = collection(db, 'transactions');
        const emailQuery = query(collectionRef, where('email', '==', user.email));
        const emailQuerySnapshot = await getDocs(emailQuery);
        
        if (!emailQuerySnapshot.empty) {
          // console.error('Transactions exists');
          const transactionDetailsArray = emailQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setTransactionDetails(transactionDetailsArray);
          return;
        }

        await addDoc(collectionRef, myTransactions);
        console.log('Transaction data added successfully');
      } catch (error) {
        console.error('Error adding transaction data: ', error);
      }
  };

  // Check if user is authenticated
  if (!user) {
    // Redirect unauthenticated users to the login page
    return <Navigate to="/authentication" />;
  }

  // Check if user's role is allowed to access the route
  if (allowedRoles && allowedRoles.length > 0 && !allowedRoles.includes(user.role)) {
    // Redirect users with unauthorized roles to a different page (e.g., 403 Forbidden)
    return <Navigate to="/forbidden" />;
  }

  // Render the protected route content if user is authenticated and has the correct role
  return <DashboardDefault />
};


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: < MainLayout />,
  children: [
    {
      path: '/',
      element: <ProtectedRoute/>
    },
    {
      path: 'deals',
      children: [
        {
          path: '',
          element: <DealsPage />
        }
      ]
    },
    {
      path: 'transactions',
      children: [
        {
          path: '',
          element: <TransactionsPage />
        }
      ]
    },
    {
      path: 'automations',
      children: [
        {
          path: '',
          element: <AutomationsPage />
        }
      ]
    },
    {
      path: 'withdraw',
      children: [
        {
          path: '',
          element: <WithdrawPage />
        }
      ]
    },
    {
      path: 'instructions',
      element: <InstructionsPage />
    },
    {
      path: 'upgrade',
      element: <UpgradePage />
    },
    {
      path: 'claim',
      element: <ClaimsPage />
    }
  ]
};

export default MainRoutes;
