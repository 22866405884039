import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';

const InstructionsPage = () => {
  const navigate = useNavigate();

  return (
    <MainCard title="Deals">
      <Typography variant="body2">
        Deals are only reserved for our Premium customers.{" "}
        <span
          onClick={() => navigate('/upgrade', { replace: true })}
          style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}
          role="button" // Making it accessible as a clickable element
          tabIndex={0} // Allows keyboard navigation
          onKeyPress={(e) => e.key === 'Enter' && navigate('/upgrade', { replace: true })}
        >
          Click Here to Upgrade
        </span>
      </Typography>
    </MainCard>
  );
};

export default InstructionsPage;
