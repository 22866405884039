import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import firebase from 'firebase/app';
// import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCAbrjyNwUqTMtrnvl1zdH6FdXRtb5f_IE",
    authDomain: "automate-web.firebaseapp.com",
    projectId: "automate-web",
    storageBucket: "automate-web.appspot.com",
    messagingSenderId: "189738855795",
    appId: "1:189738855795:web:23f005abf436f0422d2906",
    measurementId: "G-NP4G5MDSKX"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

// export default firebaseConfig;
