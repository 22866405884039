// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const AutomationsPage = () => (
  <MainCard title="Automations">
    <Typography variant="body2">
      You currently do not have any active Automations.
    </Typography>
  </MainCard>
);

export default AutomationsPage;
