// assets
import { IconBrandChrome, IconListDetails, IconBusinessplan, IconUser, IconBooks, IconCash } from '@tabler/icons-react';

// constant
const icons = { IconBrandChrome, IconListDetails, IconBusinessplan, IconUser, IconBooks, IconCash };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    {
      id: 'instructions-page',
      title: 'Instructions',
      type: 'item',
      url: '/instructions',
      icon: icons.IconBooks,
      breadcrumbs: false
    },
    {
      id: 'automations-page',
      title: 'Automations',
      type: 'item',
      url: '/automations',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: '/transactions',
      icon: icons.IconListDetails,
      breadcrumbs: false
    },
    {
      id: 'deals',
      title: 'Deals',
      type: 'item',
      url: 'deals',
      icon: icons.IconBusinessplan,
      breadcrumbs: false
    },
    {
      id: 'withdraw',
      title: 'Withdraw',
      type: 'item',
      url: 'withdraw',
      icon: icons.IconCash,
      breadcrumbs: false
    }
  ]
};

export default other;
