// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const WithdrawPage = () => (
  <MainCard title="Withdraw">
    <Typography variant="body2">Your account is not eligible for withdraws. In order to become eligible for Bonus Withdraws, you must have at least 2 paid referrals or must be a pro user. To become a pro user <a style={{color: 'blue'}}>Click Here</a> and upgrade your account to enable bonus withdraws. </Typography>
    <Typography variant="body2">You can also topup on your $50.00 bonus to activate Pro Account.</Typography>
  </MainCard>
);

export default WithdrawPage;
